import { Helmet } from 'react-helmet-async'

interface InjectTitleProps {
  title: string
}

export default function InjectTitle ({ title }: InjectTitleProps): JSX.Element {
  const trimmedTitle = title.trim()
  return (
    <Helmet>
      <title>Structure Profiles{trimmedTitle.length > 0 ? `: ${trimmedTitle}` : ''}</title>
    </Helmet>
  )
}
