import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import useUser from '../data/useUser'
import { logout } from '../lib/auth'

const MainNav = (): JSX.Element => {
  const user = useUser()
  return (
    <>
      <Navbar bg='primary' variant='dark'>
        <Link to='/'>
          <img src='https://static.da-io.net/images/da-logo.png' alt='DA logo' height='38' />
        </Link>
        <LinkContainer to='/'>
          <Navbar.Brand>
            Structure Profiles
          </Navbar.Brand>
        </LinkContainer>
      </Navbar>
      <Nav className='sub-nav'>
        <LinkContainer to='/'><Nav.Link>Home</Nav.Link></LinkContainer>
        {user != null && (
          <>
            <Nav.Item className='ml-auto'>
              <Nav.Link disabled>Logged in as {user.userInfo.firstname} {user.userInfo.surname}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={logout} href='https://login.voteda.org/logout'>Logout</Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </>
  )
}

export default MainNav
