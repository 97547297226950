import { useQuery } from '@apollo/client'
import { ForbiddenError } from '../lib/errors'
import { NAME_SEARCH } from './queries'
import type { StructureWithParents } from '../lib/structures'

interface UseNameSearchReturns {
  loading: boolean
  structures?: StructureWithParents[]
}

export default function useNameSearch (searchText: string): UseNameSearchReturns {
  const { error, loading, data } = useQuery<{ structures: StructureWithParents[] }, { searchText: string }>(
    NAME_SEARCH,
    {
      variables: { searchText },
      skip: searchText.length < 3
    }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return {
    loading,
    structures: data?.structures
  }
}
