import React from 'react'
import ReactDOM from 'react-dom'
import 'leaflet/dist/leaflet.css'
import './index.css'
import App from './App'

const container = document.getElementById('root') as HTMLElement
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container)
