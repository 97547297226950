import { gql } from '@apollo/client'
import type { StructureBase, StructureWithParents } from '../lib/structures'

const structureFields = 'type code name userHasAccess'

export interface Profile extends StructureBase {
  areaKm2: number
  parents: StructureBase[]
  children: StructureBase[]
  allElections: Election[]
  census: Census
}

export interface Election {
  electionDate: string
  electionType: 'npe' | 'lge'
  regpop: number
  turnout: number
  ballots: Ballot[]
}

export interface Ballot {
  ballotType: string
  valid: number
  spoilt: number
  total: number
  parties: PartyResult[]
}

export interface PartyResult {
  party: Party
  votes: number
}

interface Party {
  name: string
  abbrev: string
}

interface Census {
  population: number
  households: number
  variables: CensusVariable[]
}

export interface CensusVariable {
  code: string
  descrip: string
  values: Array<{
    label: string
    value: number
  }>
}

export interface ProfileVars {
  type: string
  code: string
}

export interface ProfileResult {
  profile: Profile
}

export interface PartialProfileResult {
  profile: Partial<Profile>
}

export const GET_PROFILE = gql`
query getProfile ($type: String!, $code: String!) {
  profile: structure(type: $type, code: $code) {
    ${structureFields}
    areaKm2
    parents { ${structureFields} }
    children { ${structureFields} }
    allElections {
      electionDate
      electionType
      turnout
      regpop
      ballots {
        ballotType
        valid
        spoilt
        total
        parties {
          party { name abbrev }
          votes
        }
      }
    }
    census (censusYear: "2011") {
      population
      households
      variables: allVariables {
        code
        descrip
        values {
          label
          value
        }
      }
    }
  }
}
`

export const NAME_SEARCH = gql`
query nameSearch ($searchText: String!) {
  structures: nameSearch (searchText: $searchText) {
      ${structureFields}
      parents { ${structureFields} }
  }
}
`

export interface Person {
  darn: number
  firstname: string
  surname: string
  position: string
}

export interface User {
  userInfo: Person
  userStructures: StructureWithParents[]
}

export const GET_USER = gql`
query getUserData {
  userInfo {
    darn surname firstname position
  }
  userStructures {
    ${structureFields}
    parents { ${structureFields} }
  }
}
`
