import { useCallback, useRef, useState } from 'react'
import { ListGroup, Row, Col, FormControl } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { CaretRight } from 'react-bootstrap-icons'
import { debounce } from 'lodash'

import useUser from '../data/useUser'
import { compareStrings, firstCap } from '../lib/utils'
import { structConf } from '../lib/structures'
import { LoadingSpinner } from './spinners'
import InjectTitle from './InjectTitle'
import useNameSearch from '../data/useNameSearch'
import './IndexView.css'

const sortKey: Record<string, number> = {
  province: 1,
  region: 2,
  constituency: 3,
  municipality: 4,
  ward: 5,
  votingdistrict: 6
}

export default function IndexView (): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState('')
  const handleSearchChange = useCallback(
    debounce(() => { setSearchText(searchRef.current?.value?.trim() ?? '') }, 750),
    []
  )

  const user = useUser()
  const { loading: searching, structures: searchResults } = useNameSearch(searchText)

  if (user === undefined) {
    return <LoadingSpinner />
  }
  const { userStructures } = user

  const sortedStructures = (searchText.length >= 3 && searchResults != null)
    ? searchResults.filter(s => s.userHasAccess)
    : [...userStructures] // copy because Apollo may hand us an Immutable

  sortedStructures.sort((a, b) => {
    if (a.type === b.type) {
      return compareStrings(a.name, b.name)
    } else {
      return sortKey[a.type] - sortKey[b.type]
    }
  })

  return (
    <>
      <InjectTitle title='' />

      <h2>Structure Profiles</h2>

      <p>
        This app provides political and demographic information about
        administrative structures (such as municipalities, wards and voting
        districts) as well as party structures (such as regions and
        constituencies). Type a name into the search box below to search the
        database, or click on one of the items to browse.
      </p>

      <FormControl
        className='search mb-3'
        size='lg'
        placeholder='Type at least 3 letters to search'
        ref={searchRef}
        onChange={handleSearchChange}
      />

      {searching
        ? <LoadingSpinner />
        : (
          <ListGroup>
            {sortedStructures.map(structure => {
              const { type, code } = structure
              const sc = structConf[type]
              const title = firstCap(sc.displayTitle)
              const name = sc.formatter != null ? sc.formatter(structure) : structure.name
              return (
                <LinkContainer key={`${type}:${code}`} to={`/${type}/${code}`}>
                  <ListGroup.Item action>
                    <Row>
                      <Col>
                        <span className='text-muted'>{title}:</span>{' '}
                        {name}{' '}
                        <span className='text-muted'>({code})</span>
                      </Col>
                      <Col xs='auto'>
                        <CaretRight />
                      </Col>
                    </Row>

                  </ListGroup.Item>
                </LinkContainer>
              )
            })}
          </ListGroup>
          )}
    </>
  )
}
