import Alert from 'react-bootstrap/Alert'

interface ErrorMessageProps {
  message: string
}

export const ErrorMessage = ({ message }: ErrorMessageProps): JSX.Element => (
  <Alert variant='danger'>
    <Alert.Heading>Unfortunately an error has occurred.</Alert.Heading>

    <b>Please check your internet connection and try refreshing this page.</b>

    <hr />

    <p>If errors continue to occur please contact <a href='mailto:support@da.org.za'>support@da.org.za</a> for assistance with the following technical details:</p>

    <blockquote><pre>{message}</pre></blockquote>
  </Alert>
)

interface ForbiddenMessageProps {
  forbiddenItem: string
}

export const ForbiddenMessage = ({ forbiddenItem }: ForbiddenMessageProps): JSX.Element => (
  <Alert variant='danger'>
    You are not allowed access to {forbiddenItem}. For assistance please contact <a href='mailto:support@da.org.za'>support@da.org.za</a>.
  </Alert>
)
