import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import { LoadingSpinner } from './spinners'
import IndexView from './IndexView'
const ProfileView = lazy(async () => await import('./ProfileView'))

const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path='/' element={<IndexView />} />
      <Route path='/:type/:code' element={<ProfileView />} />
    </Routes>
  </Suspense>
)

export default AppRoutes
