import { compareStrings } from '../lib/utils'

export type StructType = 'country' | 'province' | 'region' | 'constituency' | 'municipality' | 'branch' | 'ward' | 'votingdistrict'

export function isStructType (type: string): type is StructType {
  return Object.prototype.hasOwnProperty.call(structConf, type)
}

export interface StructParams {
  type: StructType
  code: string
}

export function isStructParams (obj: any): obj is StructParams {
  const { type, code } = obj
  if (typeof type !== 'string' || typeof code !== 'string') return false
  if (!isStructType(type)) return false
  return true
}

export interface StructureBase extends StructParams {
  name: string
  userHasAccess: boolean
}

export interface StructureWithParents extends StructureBase {
  parents: StructureBase[]
}

interface StructConf {
  displayTitle: string
  children: StructType[]
  mapChildren?: StructType
  formatter?: (structure: StructureWithParents) => string
}

export const structConf: Record<StructType, StructConf> = {
  country: {
    displayTitle: 'country',
    children: ['province'],
    mapChildren: 'province',
    formatter: s => s.name
  },
  province: {
    displayTitle: 'province',
    children: ['region', 'constituency', 'municipality'],
    mapChildren: 'municipality',
    formatter: p => p.name
  },
  region: {
    displayTitle: 'region',
    children: ['constituency'],
    mapChildren: 'constituency',
    formatter: r => r.name
  },
  constituency: {
    displayTitle: 'constituency',
    children: ['ward', 'branch'],
    mapChildren: 'ward',
    formatter: c => {
      const prov = c.parents.find(({ type }) => type === 'province') as StructureBase
      const region = c.parents.find(({ type }) => type === 'region')
      return `${c.name}, ${region?.name ?? prov?.name}`
    }
  },
  municipality: {
    displayTitle: 'municipality',
    children: ['ward'],
    mapChildren: 'ward',
    formatter: m => {
      const prov = m.parents.find(({ type }) => type === 'province') as StructureBase
      return `${m.name}, ${prov.name}`
    }
  },
  branch: {
    displayTitle: 'branch',
    children: ['ward', 'votingdistrict'],
    mapChildren: 'votingdistrict'
  },
  ward: {
    displayTitle: 'ward',
    children: ['votingdistrict'],
    mapChildren: 'votingdistrict',
    formatter: w => {
      const prov = w.parents.find(({ type }) => type === 'province') as StructureBase
      const muni = w.parents.find(({ type }) => type === 'municipality') as StructureBase
      return `Ward ${parseInt(w.code) % 1000}, ${muni.name}, ${prov.name}`
    }
  },
  votingdistrict: {
    displayTitle: 'voting district',
    children: [],
    formatter: v => {
      const prov = v.parents.find(({ type }) => type === 'province') as StructureBase
      const muni = v.parents.find(({ type }) => type === 'municipality') as StructureBase
      const ward = v.parents.find(({ type }) => type === 'ward') as StructureBase
      return `${v.name}, Ward ${parseInt(ward.code) % 1000}, ${muni.name}, ${prov.name}`
    }
  }
}

export function getPreferredChildType ({ type, code }: StructParams): StructType | undefined {
  if (type === 'province') {
    return (code === 'GP' || code === 'WC') ? 'region' : 'constituency'
  } else {
    return structConf[type].children[0]
  }
}

export function filterAndSortChildren<T extends StructureBase> (type: StructType, children: T[]): T[] {
  let sortedChildren = [...children]
  if (type === 'province') {
    const hasRegions = children.some(c => c.type === 'region')
    sortedChildren = children.filter(c => c.type === (hasRegions ? 'region' : 'constituency'))
    sortedChildren.sort((a, b) => compareStrings(a.name, b.name))
  } else if (type === 'constituency' || type === 'municipality') {
    sortedChildren.sort((a, b) => compareStrings(a.code, b.code))
  } else {
    sortedChildren.sort((a, b) => compareStrings(a.name, b.name))
  }
  return sortedChildren
}

export function hasParent (structure: StructureWithParents, { type, code }: StructParams): boolean {
  return (structure.type === type && structure.code === code) || structure.parents.some(p => p.type === type && p.code === code)
}
