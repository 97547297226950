import { useQuery } from '@apollo/client'
import { ForbiddenError } from '../lib/errors'
import { GET_USER, type User } from './queries'

export default function useUser (): User | undefined {
  const { error, data } = useQuery<User>(GET_USER)

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this app')
  }
  if (error != null) throw error

  return data
}
