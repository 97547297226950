import { ForbiddenError } from '../lib/errors'
import { ErrorMessage, ForbiddenMessage } from './alerts'

export default function ErrorDisplay ({ error }: { error: any }): JSX.Element {
  if (error instanceof ForbiddenError) {
    return <ForbiddenMessage forbiddenItem={error.message} />
  } else {
    return <ErrorMessage message={error.message} />
  }
}
