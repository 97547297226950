import Spinner from 'react-bootstrap/Spinner'

export const LoggingInSpinner = (): JSX.Element => (
  <>
    <Spinner animation='border' variant='primary' role='status' />{' '}
    <span>Logging in...</span>
  </>
)

export const LoadingSpinner = (): JSX.Element => (
  <Spinner animation='border' variant='primary' role='status'>
    <span className='sr-only'>Loading...</span>
  </Spinner>
)
