import { AuthContext, useAuthentication, clearLocalToken } from '@adrianfrith/react-u5auth'
import { LoggingInSpinner } from '../components/spinners'
import type { WithChildren } from '../lib/utils'

const clientId = import.meta.env.VITE_AUTH_CLIENT_ID
const authProvider = import.meta.env.VITE_AUTH_PROVIDER ?? 'https://login.voteda.org'

export const AuthProvider = ({ children }: WithChildren): JSX.Element => (
  <AuthContext clientId={clientId} provider={authProvider}>
    {children}
  </AuthContext>
)

export const Authenticated = ({ children }: WithChildren): JSX.Element => {
  const { authenticated } = useAuthentication()
  return authenticated ? <>{children}</> : <LoggingInSpinner />
}

export const logout = (): void => {
  clearLocalToken()
  window.location.href = 'https://login.voteda.org/logout'
}
