import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloProvider as ActualApolloProvider
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import { getLocalToken } from '@adrianfrith/react-u5auth'
import type { WithChildren } from '../lib/utils'

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors != null) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations?.toString() ?? ''}, Path: ${path?.toString() ?? ''}`)
        )
      }
      if (networkError != null) console.log(`[Network error]: ${networkError.message}`)
    }),
    setContext((_, { headers }) => {
      const token = getLocalToken()
      return {
        headers: {
          ...headers,
          Authorization: (token != null) ? `Bearer ${token}` : ''
        }
      }
    }),
    createPersistedQueryLink({ sha256 }),
    new HttpLink({
      uri: import.meta.env.VITE_API_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Person: { keyFields: ['darn'] },
      Structure: { keyFields: ['type', 'code'] },
      Query: {
        fields: {
          structure: {
            read: (_, { args, toReference }) => toReference({
              __typename: 'Structure',
              type: args?.type,
              code: args?.code
            })
          }
        }
      }
    }
  })
})

export const ApolloProvider = ({ children }: WithChildren): JSX.Element => (
  <ActualApolloProvider client={apolloClient}>
    {children}
  </ActualApolloProvider>
)
